import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageData, PageType } from '@/components/Page';
import Blog, { BlogType } from '@/components/blog/Blog';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import { HighlightedArticleBlockType } from '@/components/blog/HighlightedArticleBlock';
import { sortArticlesByDate } from '@/utils/DateUtils';
import { createArticleTagMetaData } from '@/utils/MetaDataUtils';
type BlogTypeData = BlogType & {
  page: number;
  total: number;
  totalPages: number;
  articles: Array<ArticleBlockType>;
};

type BlogPageData = PageData & {
  pageType: BlogTypeData;
};
type Props = PageType & {
  data: {
    contentfulPage: BlogPageData;
    relatedArticles: {
      nodes: Array<ArticleBlockType>;
    };
    contentfulArticle: HighlightedArticleBlockType;
  };
  pageContext: {
    page: number;
    total: number;
    totalPages: number;

    tag: string;
    blogPath: string;
  };
};

const BlogPage: React.FC<Props> = (props) => {
  const page = props.data.contentfulPage;
  const blog: BlogType = {
    ...page.pageType,
    page: props.pageContext.page,
    total: props.pageContext.total,
    totalPages: props.pageContext.totalPages,
    articles: sortArticlesByDate(props.data.relatedArticles.nodes),
    currentTag: props.pageContext.tag,
    blogPath: props.pageContext.blogPath,
    highlightedArticle: props.data.contentfulArticle,
  };
  if (blog.currentTag && !page.updated)
    createArticleTagMetaData(blog.currentTag, props.data.contentfulPage);
  return (
    <Page {...props}>
      <Blog {...blog} />
    </Page>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
    $highlightedArticle: String
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      ...PagePathFragment

      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulBlogPageType {
          ...BlogFragment
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    contentfulArticle(
      contentful_id: { eq: $highlightedArticle }
      node_locale: { eq: $locale }
    ) {
      ...HighlightedArticleBlockFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
  }
`;
