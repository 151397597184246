import { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  SearchIndex,
  SearchOptions,
  SearchResponse,
} from '@algolia/client-search';

import { useLocalization } from '@/../plugins/keytrade-localization/useLocalization';

type HookReturn = {
  index: SearchIndex;
  search: (s: string) => Promise<SearchResponse<unknown>>;
  isLoading: boolean;
};

const useAlgolia = (searchOption: SearchOptions = {}): HookReturn => {
  const { locale } = useLocalization();
  const [isLoading, setIsLoading] = useState(false);

  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );

  const index = client.initIndex(`${process.env.GATSBY_ENVIRONMENT}-${locale}`);

  const search = async (s: string) => {
    try {
      setIsLoading(true);
      const response = await index.search(s, searchOption);
      setIsLoading(false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    index,
    search,
    isLoading,
  };
};

export default useAlgolia;
