import React, { useState } from 'react';
import styled from 'styled-components';
import { Hit } from '@algolia/client-search';

import useTranslations from '@/hooks/useTranslations';
import useAlgolia from '@/hooks/useAlgolia';
import useDebounceEffect from '@/hooks/useDebounceEffect';
import SearchBox from '@/components/search/SearchBox';
import { ArticleHit } from '@/types/Algolia';

import BlogSearchResults from './BlogSearchResults';

const MIN_CHAR_COUNT = 2;

const SearchWrapper = styled.div`
  position: relative;
  margin-top: 3.2rem;
`;

const BlogSearch: React.FC = () => {
  const { t } = useTranslations();
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchHits, setSearchHits] = useState<Hit<ArticleHit>[]>([]);

  const { search, isLoading } = useAlgolia({ filters: 'pageType: articles' });

  const closeSearchResults = () => {
    setIsSearchOpen(false);
  };

  const clearHits = () => {
    closeSearchResults();
    setSearchHits([]);
  };

  const clearValue = () => {
    setSearchValue('');
    clearHits();
  };

  const doSearch = (value: string) => {
    setSearchValue(value);
  };

  useDebounceEffect(async () => {
    if (searchValue.length <= MIN_CHAR_COUNT) {
      clearHits();
      return;
    }

    const { hits } = await search(searchValue);
    setSearchHits(hits as Hit<ArticleHit>[]);

    if (!isSearchOpen) {
      setIsSearchOpen(true);
    }
  }, [searchValue]);

  return (
    <SearchWrapper>
      <SearchBox
        title={t('search.blogPlaceholder')}
        doSearch={doSearch}
        value={searchValue}
        clearValue={clearValue}
        isSearching={isSearchOpen}
        noMargin
      />
      {isSearchOpen && (
        <BlogSearchResults
          hits={searchHits.slice(0, 5)}
          isLoading={isLoading}
          onClose={closeSearchResults}
        />
      )}
    </SearchWrapper>
  );
};

export default BlogSearch;
