/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DependencyList, useEffect, useRef } from 'react';

const useDebounceEffect = (
  callback: () => void,
  deps: DependencyList = [],
  delay = 250,
): void => {
  const timeout = useRef<number>();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      // @ts-ignore
      timeout.current = setTimeout(callback, delay);
    }

    return () => {
      mounted.current = true;

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, deps);
};

export default useDebounceEffect;
