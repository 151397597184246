import { useEffect, useRef, RefObject } from 'react';

const useCloseOnOutsideClick = <T extends HTMLElement>(
  onClose: () => void,
): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const closeSearchIfOutside = (e) => {
      if (!ref.current?.contains(e.target)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', closeSearchIfOutside);

    return () => {
      window.removeEventListener('mousedown', closeSearchIfOutside);
    };
  }, [ref]);

  return ref;
};

export default useCloseOnOutsideClick;
