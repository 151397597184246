import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { Link as SCLink } from '@keytrade/components-link';

import { mediaQuery } from '@/utils/helpers';
import { getBackgroundColor } from '@/utils/StyleUtils';

const { list: colors } = config.colors;

export const Wrapper = styled.div`
  position: absolute;
  top: 5.4rem;
  left: 0;
  right: 0;
  background-color: ${colors.White};
  border: 1px solid ${colors.BlueFog};
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  z-index: 99;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  font-size: 2rem;

  em {
    font-style: normal;
    background-color: ${getBackgroundColor('Highlight')};
  }
`;

export const Link = styled(SCLink)`
  display: inline-block;
  width: 100%;
  padding: 0.8rem 1.2rem;
  color: ${colors.BlueDark};

  &:focus,
  &:hover {
    background-color: ${colors.GreyBackground};
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.2rem 3.6rem 7.2rem 0;

  ${mediaQuery.lg(`
    padding:0;
    min-height: 25.4rem;
  `)}
`;
