import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import slugify from 'slugify';
import { Pagination } from '@keytrade/components-pagination';
import { Tabs } from '@keytrade/components-tabs';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import ArticlesBlock from '@/components/blog/ArticlesBlock';
import Title from '@/components/Title';
import Container from '@/components/Container';
import { HighlightedArticleBlockType } from '@/components/blog/HighlightedArticleBlock';
import { getBackgroundColor } from '@/utils/StyleUtils';
import useTranslations from '@/hooks/useTranslations';
import '@/components/Page';
import useIsMobile from '@/hooks/useIsMobile';

import { ArticleTagType } from './ArticleTag';
import BlogSearch from './search/BlogSearch';
import './Author';

export type BlogType = {
  title: string;
  currentTag: string;
  tags: Array<ArticleTagType>;
  highlightedArticle?: HighlightedArticleBlockType;
  page: number;
  total: number;
  totalPages: number;
  articles: Array<ArticleBlockType>;
  blogPath: string;
};

const BlogContent = styled.section`
  padding-top: 13rem;
  padding-bottom: 11.2rem;
  position: relative;

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 48rem;
    z-index: -1;
    background: ${getBackgroundColor('Light blue')};
  }
`;

const BGWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 5rem;
    z-index: -1;
    background: ${getBackgroundColor('Light blue')};
  }
`;

const BlogTabWrapper = styled.div`
  background: ${getBackgroundColor('Light blue')};
`;

type Props = BlogType;

const Blog: React.FC<Props> = ({
  title,
  currentTag,
  tags,
  highlightedArticle,
  articles,
  page,
  totalPages,
  blogPath,
}) => {
  const { t } = useTranslations();
  const { navigate } = useLocalization();
  const allTag = t('all');

  const getTagPath = (tag: string) => {
    return tag === allTag
      ? '/'
      : `/${slugify(tag, { lower: true, strict: true })}/`;
  };

  const tag = currentTag ?? allTag;
  const tagPath = getTagPath(tag);
  const tagLabels = [allTag].concat(tags.map((tag) => tag.text));

  const onPageClick = (pageNr) => {
    const currentPage = pageNr === 1 ? '' : `${pageNr}`;
    navigate(`${blogPath}${tagPath}${currentPage}`);
  };

  const onTabClick = (tab) => {
    navigate(`${blogPath}${getTagPath(tab)}`);
  };

  articles.forEach((article) => {
    article.blogPath = blogPath;
  });

  if (highlightedArticle) {
    highlightedArticle.blogPath = blogPath;
  }

  const isMobile = useIsMobile();

  return (
    <BlogContent>
      <BlogTabWrapper>
        <Container narrow={true}>
          <Title size='xxl' level='h1' margin='3.2rem 0 0'>
            {title}
          </Title>
          <BlogSearch />
          <Tabs
            type='pills'
            colorScheme='lightInverted'
            activeIndex={tagLabels.indexOf(tag)}
            onTabChange={onTabClick}
            styledComponentsV4
            data={tagLabels.map((tagItem) => {
              return {
                name: tagItem,
                content: <></>,
              };
            })}
            wrap={!isMobile}
          />
        </Container>
      </BlogTabWrapper>
      <BGWrapper>
        <Container narrow={true}>
          <ArticlesBlock
            articles={articles}
            highlightedArticle={highlightedArticle}
          />
          <Pagination
            numberPages={totalPages}
            selectedPage={page}
            onClick={onPageClick}
            transparentBackground={false}
            prevAccessibilityLabel={t('previous')}
            nextAccessibilityLabel={t('next')}
            margin={'7.2rem 0 0 0'}
          />
        </Container>
      </BGWrapper>
    </BlogContent>
  );
};

export default Blog;

export const blogQuery = graphql`
  fragment BlogFragment on ContentfulBlogPageType {
    title
    tags {
      ...ArticleTagFragment
    }
  }
`;
