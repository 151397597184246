import { useState, useEffect } from 'react';
import window from 'global';
import { BREAKPOINTS } from '@/utils/constants';
export const getIsMobile = (): boolean =>
  window && window.innerWidth <= BREAKPOINTS.MD;

export default function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobile;
}
