import React, { useEffect } from 'react';
import { Hit } from '@algolia/client-search';
import sanitizeHtml from 'sanitize-html';
import { Icon } from '@keytrade/components-icon';
import { LoaderSpinner } from '@keytrade/components-loader';

import useCloseOnOutsideClick from '@/hooks/useCloseOnOutsideClick';
import NoResults from '@/components/search/NoResults';
import { ArticleHit } from '@/types/Algolia';

import {
  Wrapper,
  List,
  ListItem,
  Link,
  Loader,
} from './BlogSearchResults.styled';

type Props = {
  hits: Hit<ArticleHit>[];
  isLoading: boolean;
  onClose: () => void;
};

const ARROW_ICON = <Icon icon='icn_longArrowRight' margin='0 1.6rem 0 0' />;

const BlogSearchResults: React.FC<Props> = (props) => {
  const { hits, isLoading, onClose } = props;
  const wrapperRef = useCloseOnOutsideClick<HTMLDivElement>(onClose);

  const hasNoResults = !isLoading && !hits?.length;
  const hasResults = !isLoading && !!hits?.length;

  return (
    <Wrapper ref={wrapperRef}>
      {isLoading && (
        <Loader>
          <LoaderSpinner />
        </Loader>
      )}
      {hasNoResults && <NoResults />}
      {hasResults && (
        <List>
          {hits.map((hit) => {
            return (
              <ListItem key={hit.objectID}>
                <Link
                  href={hit.url}
                  iconLeft={ARROW_ICON}
                  color='GreyDark'
                  size='lg'
                  isSemiBold
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        hit._highlightResult.title
                          ? hit._highlightResult.title.value
                          : hit.title,
                      ),
                    }}
                  />
                </Link>
              </ListItem>
            );
          })}
        </List>
      )}
    </Wrapper>
  );
};

export default BlogSearchResults;
